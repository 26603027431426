<template>
    <div class="text-nowrap">
        <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
            @click.stop="row.toggleDetails" class="mr-1">
            <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
            <i class="fa fa-eye" v-else></i>
        </b-button>
        <b-button v-if="isAllowedToEdit(row.item)" size="sm" v-b-tooltip.hover.top="'Edit Details'" variant="warning"
            @click.stop="onActions(row.item, 'edit')" class="mr-1" v-show="!isViewer">
            <i class="fa fa-pencil"></i>
        </b-button>
        <b-button v-if="isAllowedToApprove(row.item)" size="sm" v-b-tooltip.hover.top="'Approve Report'"
            variant="success" @click.stop="onActions(row.item, 'approve')" class="mr-1" v-show="!isViewer">
            <i class="fa fa-check"></i>
        </b-button>
        <b-button v-if="isAllowedToCancel(row.item)" size="sm" v-b-tooltip.hover.top="'Cancel Report'" variant="danger"
            @click.stop="onActions(row.item, 'cancel')" class="mr-1" v-show="!isViewer">
            <em class="fa fa-ban"></em>
        </b-button>
        <b-button v-if="isAllowedToPrint(row.item)" size="sm" v-b-modal.print-billing-report
            v-b-tooltip.hover.top="'Print Report'" variant="primary" @click.stop="onActions(row.item, 'print')"
            class="mr-1">
            <em class="fa fa-print" style="color: #fff"></em>
        </b-button>
    </div>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';

export default {
    name: 'billing-report-row-actions',
    props: {
        row: {
            type: Object,
            required: true,
        },
        isSuperAdmin: {
            type: Boolean,
            required: true,
        },
        isManager: {
            type: Boolean,
            required: true,
        },
        isViewer: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            loggedUser: this.$store.getters.loggedUser,
        }
    },
    methods: {
        onActions(billingReport, action) {
            this.$store.commit('SET_CURR_BILLING_REPORT', billingReport);

            switch (action) {
                case 'edit':
                    this.redirectToEditBillingReport(billingReport);
                    break;
                case 'approve':
                    EventBus.$emit('onApproveBillingReport', billingReport);
                    break;
                case 'cancel':
                    EventBus.$emit('onCancelBillingReport', billingReport);
                    break;
                case 'print':
                    EventBus.$emit('onPrintBillingReport', billingReport);
                    break;

            }
        },
        isAllowedToEdit(report) {
            return report.status === 'Pending';
        },
        isAllowedToApprove(report) {
            return (this.isManager || this.isSuperAdmin) && report.status === 'Pending';
        },
        isAllowedToCancel(report) {
            return report.status === 'Pending' && (report.createdBy === this.loggedUser.id);
        },
        isAllowedToPrint(report) {
            return report.status === 'Pending' || report.status === 'Approved';
        },

        redirectToEditBillingReport(billingReport) {
            this.$store.commit('SET_CURR_BILLING_REPORT', billingReport);

            if (this.isSuperAdmin) {
                this.$router.push({ path: '/admin/admin-edit-billing-report' });
            } else {
                this.$router.push({ path: '/edit-billing-report' });
            }
        },
    }
}
</script>