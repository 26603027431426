<template>
    <b-modal id="cancel-billing-report" ref="cancel-billing-report" :title="title" ok-title="Proceed" ok-variant="danger"
        cancel-title="Close" @ok="handleOk" :cancel-disabled="disableConfirmButtons" 
        :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <div class="confirm-message">
            Are you sure you want to cancel
            <strong class="numFont">{{ billingReport.billingReportNo }}</strong>
            ?
        </div>
        <b-form @submit.stop.prevent="handleSubmit" novalidate>
            <b-form-group class="confirm-message" label="" description="Please indicate the reason of cancellation here"
                label-for="notes">
                <b-form-textarea name="Reason" type="text" v-model="billingReport.remarks" maxlength="200"
                    v-validate="getValidationParam(true, remarksRegex)" :rows="3" placeholder="" />
                <span v-show="errors.has('Reason')" class="help-block">
                    {{ errors.first('Reason') }}
                </span>
            </b-form-group>
        </b-form>
    </b-modal>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import billingReportApi from '@/api/billingReportApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'cancel-billing-report',
    components: {
        Loading,
    },
    data() {
        return {
            billingReport: {},
            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        title() {
            return 'Cancel Billing Report ' + this.billingReportNo;
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '-';
        },
        remarksRegex() {
            return config.remarksRegex;
        },
    },
    mounted() {
        EventBus.$on('onCancelBillingReport', (billingReport) => {
            this.$bvModal.show('cancel-billing-report');
            this.onReset(billingReport);
        });
    },
    methods: {
        getValidationParam(isRequired, regex) {
            return {
                required: isRequired,
                regex: regex,
            };
        },
        getBillingReportObj() {
            // update fields
            this.billingReport.status = 'Cancelled';
            this.billingReport.dateUpdated = DateUtil.getCurrentTimestamp();
            this.billingReport.updatedBy = this.loggedUser.id;
            this.billingReport.dateCancelled = DateUtil.getCurrentTimestamp();
            this.billingReport.cancelledBy = this.loggedUser.id;

            return BillingReportUtil.cleanupFields(this.billingReport);
        },
        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            try {
                // show loading indicator
                this.isLoading = true;

                let isValid = await this.$validator.validateAll();
                if (!isValid) {
                    this.$toaster.warning('Please address the field/s with invalid input');
                    this.isLoading = false;
                    return;
                }

                let reportObj = this.getBillingReportObj();

                let { data } = await billingReportApi.saveBillingReport(
                    reportObj,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    this.$toaster.success(`Billing Report "${this.billingReportNo}" was cancelled successfully.`);
                    this.$bvModal.hide('cancel-billing-report');
                    EventBus.$emit('onCloseSaveBillingReport', data.billingReport);
                } else {
                    this.$toaster.warning(data.message);
                }

            } catch (error) {
                this.$toaster.error(`Error cancelling billing report "${this.billingReportNo}". Please try again.`);
            }

            // hide loading indicator
            this.isLoading = false;
        },
        onReset(billingReport) {
            if (billingReport && !_.isEmpty(billingReport)) {
                this.billingReport = { ...billingReport };
            }
        }
    },
    beforeDestroy() {
        EventBus.$off('onCancelBillingReport');
    },
};
</script>